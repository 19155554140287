
import { Options, Vue } from 'vue-class-component';
import IWorkflowDefinition from '@/models/IWorkflowDefinition';
import { AlertService, DipODataStore, GenericErrorHandler, SmartTableV2, Titlebar } from 'rey-web-toolkit';
import { DxButton, DxColumn, DxLoadPanel, DxLookup, DxMasterDetail, DxSelection } from 'devextreme-vue/data-grid';
import WorkflowTypeEnum from '@/models/WorkflowTypeEnum';
import axios from 'axios';
import { Watch } from 'vue-property-decorator';
import DxTooltip from 'devextreme-vue/tooltip';
import { Container } from 'typescript-ioc';
import FeatureFlagService from '@/services/FeatureFlagService';

@Options({
  components: {
    DxColumn,
    DxSelection,
    DxLoadPanel,
    DxLookup,
    DxMasterDetail,
    DxButton,
    SmartTableV2,
    Titlebar,
    DxTooltip,
  },
})
export default class WorkflowEditorOverview extends Vue {
  private alertService: AlertService = new AlertService();
  public isLoading = false;
  public featureFlagService = Container.get<FeatureFlagService>(FeatureFlagService);
  public dataStore: any | undefined = undefined;
  public workflowTypes = [
    { key: WorkflowTypeEnum.Workstation, name: 'Arbeitsstation' },
    { key: WorkflowTypeEnum.Background, name: 'Hintergrund' },
    { key: WorkflowTypeEnum.BackgroundForLogs, name: 'Hintergrund für LogEvents' },
  ];
  public exportPossible = false;

  public async beforeCreate() {
    document.title = 'System - Workflow Übersicht';
    this.dataStore = {
      store: DipODataStore.forODataV4Endpoint(
        {
          url: process.env.BASE_URL + `odata/WorkflowDefinition`,
          key: 'key',
          keyType: 'Guid',
          // Parse a date provided in ISO in a correct way:
          // w/o this flag the transferred datetime eg `2022-04-21T11:38:17.024038Z` will be displayed as `21.4.2022, 11:38` (browser located at de-ch in April (so it's DST)).
          // 11:38 is not correct! It is a point in time; in the timezone ZULU (`Z` -> GMT+0). During DST we are at GMT+2 in de-ch. So it must be `21.4.2022, 13:38`!
          deserializeDates: false,
        },
        [],
        (error: any) => GenericErrorHandler.handleODataV4Error(error),
      ),
      sort: 'title',
    };
  }

  public openEditor(event: { row: { data: IWorkflowDefinition } }) {
    this.$router.push({ path: `/WorkflowEditor/${event.row.data.key.toString()}` });
  }

  public exportWorkflows() {
    this.isLoading = true;
    let selection = (this.$refs['workflowOverview'] as any)?.$refs?.dxDataGrid?.instance?.getSelectedRowKeys();
    axios
      .post('/api/WorkflowDefinition/Export', selection, { responseType: 'blob' })
      .then((response) => {
        // kudos: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
        const { data, headers } = response;
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = headers['content-disposition'];
        let fileName = 'workflows.json';
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch?.length === 2) fileName = fileNameMatch[1];
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error: any) => this.alertService.add({ message: error, type: 'danger', autoCloseInMs: undefined }))
      .finally(() => (this.isLoading = false));
  }

  public importWorkflows(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length) {
      const formData = new FormData();
      formData.append('workflowDefinitionFile', files[0]);
      this.isLoading = true;
      axios
        .post('api/WorkflowDefinition/Import', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .catch((error) => this.alertService.add({ message: error, type: 'danger', autoCloseInMs: undefined }))
        .then(() => this.alertService.add({ message: 'Workflows wurden importiert.', type: 'success', autoCloseInMs: 3000 }))
        .then(() => (this.$refs['workflowOverview'] as any)?.$refs?.dxDataGrid?.instance?.getDataSource()?.reload())
        .finally(() => (this.isLoading = false));
    } else {
      console.warn(`no files provided.`);
    }
  }

  public selectWorkflowFile() {
    (this.$refs.workflowImportFileChooser as HTMLElement).click();
  }

  @Watch('isLoading')
  public onChangedIsLoading(newValue: boolean) {
    if (newValue) {
      (this.$refs['workflowOverview'] as any)?.$refs?.dxDataGrid?.instance?.beginCustomLoading();
    } else {
      (this.$refs['workflowOverview'] as any)?.$refs?.dxDataGrid?.instance?.endCustomLoading();
    }
  }

  public onWorkflowSelectionChanged(e: any) {
    this.exportPossible = e.currentSelectedRowKeys.length > 0;
  }
}
